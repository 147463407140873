import './../../scss/blocks/_partners.scss'

document.addEventListener('DOMContentLoaded', () => {
    // eslint-disable-next-line no-undef
    const swiper = new Swiper('.partners-block__slider', {
        breakpoints: {
            0: {
                slidesPerView: 2,
                spaceBetween: 12,
            },
            1110: {
                slidesPerView: 6,
                spaceBetween: 60,
            },
        },
    })

    return swiper
})
